@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&display=swap");

:root {
  --primaryColor: #30c6b5;
  --titleColor: #212121;
  --textColor: #585f5f;
  --whiteCreamColor: #fafafa;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: var(--whiteCreamColor);
  background-image: none;
  color: var(--textColor);
  font-size: 14px;
  font-weight: 300;
}

.font-color-title {
  color: var(--titleColor);
}

.font-color-primary {
  color: var(--primaryColor);
}

.font-color-text {
  color: var(--textColor);
}

.font-color-white {
  color: var(--whiteCreamColor);
}

.font-family-secondary {
  font-family: "Maven Pro", sans-serif;
}

.icon-primary svg {
  fill: var(--primaryColor);
}

.h-40px {
  height: 40px;
}
.h-52px {
  height: 52px;
}
.h-64px {
  height: 64px;
}

.min-width-400px {
  min-width: 400px;
}
.min-width-320px {
  min-width: 320px;
}

.min-width-280px {
  min-width: 280px;
}

.max-width-400px {
  max-width: 400px;
}
.max-width-320px {
  max-width: 320px;
}

.width-400px {
  width: 400px;
}
.width-320px {
  width: 320px;
}

.icon-40 svg {
  width: 40px;
  height: 40px;
}

.icon-48 svg {
  width: 48px;
  height: 48px;
}

.title-32 {
  font-family: "Maven Pro", sans-serif;
  color: var(--titleColor);
  font-size: 32px;
}

.title-40 {
  font-family: "Maven Pro", sans-serif;
  color: var(--titleColor);
  font-size: 40px;
}

.font-bold {
  font-weight: bold;
}
.font-light {
  font-weight: lighter;
}

.fs-24px {
  font-size: 24px;
}

.fs-18px {
  font-size: 18px;
}

.fs-16px {
  font-size: 16px;
}

.fs-12px {
  font-size: 12px;
}

.px-12px {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}
.mt-70px {
  margin-top: 70px;
}

.auth-page-container {
  margin-top: 48px;
  margin-bottom: 48px;
}

.auth-card {
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 540px;
}

@media only screen and (max-width: 575px) {
  .auth-card {
    width: 340px;
  }
}

.button-primary {
  background-color: var(--primaryColor);
  color: var(--whiteCreamColor);
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;
  border: none;
  padding: 0px 36px;
}

.web-form label {
  color: var(--titleColor);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.web-form input,
.web-form textarea,
.web-form select,
.web-form datalist {
  border-radius: 6px;
  height: 60px;
  border: 1px solid #adadad;
  padding: 16px 48px;
  width: 100%;
  outline: none;
}

.web-form.sign-up-form input,
.web-form.sign-up-form textarea,
.web-form.sign-up-form select,
.web-form.sign-up-form datalist {
  padding: 16px 48px 16px 32px;
}

.web-form input:focus,
.web-form textarea:focus,
.web-form select:focus,
.web-form datalist:focus {
  border: 2px solid #919099;
}

.web-form select:focus,
.web-form datalist:focus {
  box-shadow: none;
}

.web-form .form-item {
  margin-bottom: 20px;
}
.web-form.sign-up-form .form-item {
  margin-bottom: 28px;
}

/* Positions the element 4 pixels from the start edge of the container */
.icon-start {
  left: 20px;
}

/* Positions the element 4 pixels from the end edge of the container */
.icon-end {
  right: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.otp-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Adjust the height as needed */
}

.otp-box {
  background-color: rgba(49, 127, 140, 0.1);
  border-radius: 20px;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 75%;
  width: auto;
}

.otp-inner-box {
  min-height: 356px;
  width: 75%;

  display: flex;
  flex-direction: column; /* Align items vertically */
  justify-content: center; /* Center items vertically */
  align-items: center; /* Center items horizontally */
}

@media only screen and (max-width: 575px) {
  .otp-inner-box {
    width: 90%;
  }
}

.otp-box input {
  border-radius: 6px;
  height: 60px;
  width: 60px !important;
  padding: 16px;
  outline: none;
  border: 1px solid #d9d9d9;
}

.otp-box input:focus {
  border: 2px solid #919099 !important;
}

.css-a5ietl-control:hover {
  border-color: #212121 !important;
}
