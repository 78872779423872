@font-face {
  font-family: "Nexa";
  src: url("../fonts/Nexa Regular.otf") format("opentype");
}

:root {
  --white-cream: #fafafa;
  --white-border: #d9d9d9;
  --gray-dim: #b3b3b3;

  --transparent-color: rgba(0, 0, 0, 0);
  --primary-color: #30c6b5;
  --secondary-color: #e0fffc;
  --primary-color-dark: #0b685d;
  --focus-color: #058779;
  --highlight-color: rgba(160, 248, 239, 0.4);
  --title-color: #212121;
  --text-color: #585f5f;
  --text-menu-color: #d4d3d3;
  --sub-menu-color: #569e96;
  --primary-gradient: linear-gradient(
    to bottom,
    rgba(118, 183, 175, 1),
    rgba(11, 101, 91, 1)
  );
}

.inner-panel {
  font-family: "Nexa", sans-serif !important;
  color: var(--text-color);
}
body {
  background-image: url("../../public//asset/background.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.cursor-pointer {
  cursor: pointer !important;
}

.link-style {
  text-decoration: none; /* Remove underline */
  color: var(--text-color); /* Set text color to black */
}
.link-style:hover {
  text-decoration: underline; /* Remove underline */
  color: var(--primary-color); /* Set text color to black */
}

img {
  object-fit: cover;
}

.icon-40 svg {
  width: 40px;
  height: 40px;
}

.icon-text-color svg {
  fill: var(--text-color);
}

.col {
  padding: 0;
}
.text-title {
  color: var(--title-color);
}
.text-text {
  color: var(--text-color) !important;
}

.text-menu {
  color: var(--text-menu-color);
}
.text-primary-color {
  color: var(--primary-color);
}

/* Custom Font */
.fs-8 {
  font-size: 8px;
}
.fs-10 {
  font-size: 10px;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}

.fw-900 {
  font-weight: 900;
}
.fw-700 {
  font-weight: 700;
}
.fw-600 {
  font-weight: 600;
}
.fw-500 {
  font-weight: 500;
}
.fw-400 {
  font-weight: 400;
}
.fw-300 {
  font-weight: 300;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

/* Custom backgrounds */
.bg-primary-gradient {
  background: var(--primary-gradient);
}
.bg-primary-color {
  background: var(--primary-color);
}
.bg-primary-color-dark {
  background: var(--primary-color-dark);
}
.bg-top {
  background: rgba(118, 183, 175, 1);
}
.bg-secondary-color {
  background: var(--secondary-color) !important;
}
.bg-highlight {
  background-color: var(--highlight-color);
}
.bg-white-cream {
  background-color: var(--white-cream);
}
.bg-blue-faded {
  background-color: var(--blue-faded);
}
/* Custom Border */
.border-primary-color {
  border-color: var(--primary-color) !important;
}

/* Custom width/height */
.mb-170px {
  margin-bottom: 170px;
}
.h-100px {
  height: 100px;
}
.h-160px {
  min-height: 160px;
  max-height: 160px;
}
.h-400px {
  height: 400px;
}
.h-600px {
  height: 600px;
}

.min-h-600px {
  min-height: 600px;
}

.min-height-84 {
  min-height: 84px;
}

.w-249px {
  min-width: 249px;
  max-width: 249px;
}
.w-300px {
  min-width: 300px;
  max-width: 300px;
}
.w-min-250px {
  min-width: 250px;
}
.w-h-24px {
  width: 24px;
  height: 24px;
}
.w-h-33px {
  width: 33px;
  height: 33px;
}
.w-h-44px {
  width: 44px;
  height: 44px;
}
.w-h-50px {
  width: 50px;
  height: 50px;
}
.w-h-50px-max-min {
  max-width: 50px;
  min-width: 50px;

  max-height: 50px;
  min-height: 50px;
}
.input-wrapper-image {
  max-width: 200px;
  width: 100%;
  max-height: 300px;
  height: 100%;
}
.w-h-92px {
  width: 92px;
  height: 92px;
}
.w-h-130px {
  width: 130px;
  height: 130px;
}
.w-h-250px {
  width: 250px;
  height: 250px;
}
.w-h-max-500px {
  max-width: 500px;
  max-height: 500px;
}
.w-110px {
  width: 110px;
}

.w-94px {
  width: 94px;
}
.w-45 {
  width: 45%;
}
.w-10 {
  width: 10%;
}
.w-35 {
  width: 35%;
}
.w-25 {
  width: 25%;
}
.w-30 {
  width: 30%;
}
/* custom font */
.fs-14 {
  font-size: 14px;
}
.fs-12 {
  font-size: 12px;
}

/* Custom sidebar */
/* .css-dip3t8 {
  background: var(--primary-gradient);
} */

/* SideBar Styles */
.z-index-10 {
  z-index: 10;
}
.sideBar-menu .active {
  background-color: var(--focus-color);
}
.sideBar-menu .active a {
  color: white;
}
.sideBar-menu .active .menu-svg svg > path {
  fill: white;
}
.sideBar-menu .active .menu-svg svg > path {
  fill: white;
}
.sideBar-menu .active .menu-svg-1 svg > path {
  stroke: white;
}
.menu-svg svg > path {
  fill: var(--text-menu-color);
}
.menu-svg-1 svg > path {
  stroke: var(--text-menu-color);
}
.menu-svg-2 svg > circle {
  fill: var(--text-menu-color);
}

.sideBar-menu > ul > li {
  padding: 10px 15px;
  border-radius: 6px;
}

.arrow-expanded svg {
  transform: rotate(0deg);
  transition: transform 0.7s ease-in-out;
}

.arrow-collapsed svg {
  transform: rotate(-180deg);
  transition: transform 0.7s ease-in-out;
}

/* End of SideBar */

/* Topbar Styles */
.search button.btn:focus {
  border-color: transparent;
}
.search input:focus {
  box-shadow: none;
  outline: none;
}

/* End of Topbar */

/* DashBoard Styles */

.dashboard-card-list {
  overflow-y: auto;
  max-height: 220px;
}

.row-height {
  max-height: 400px !important;
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

/* Stats Card */
.stats-card:nth-child(1) {
  border-right: 1px solid var(--white-border);
  border-bottom: 1px solid var(--white-border);
}
.stats-card:nth-child(2) {
  border-bottom: 1px solid var(--white-border);
}
.stats-card:nth-child(3) {
  border-right: 1px solid var(--white-border);
}

/* Pagination Styles starts */
.custom-page-link,
.custom-previous-link,
.custom-next-link {
  text-decoration: none;
  color: var(--text-color);
  font-size: 14px;
  padding: 8px 12px;
  margin-right: 6px;
  border: 1px solid #c7c7c7;
  border-radius: 6px;
  transition: background-color 0.3s;
}
.custom-previous-link,
.custom-next-link,
.active-page-link,
.custom-page-link:hover,
.custom-previous-link:hover,
.custom-next-link:hover {
  color: white;
  background-color: var(--primary-color);
}
.active-page-link {
  cursor: not-allowed;
  border: none;
}
.custom-previous-link {
  margin-right: 12px;
}
.custom-next-link {
  margin-left: 6px;
}

.disabled-page-link {
  cursor: not-allowed;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  background: none;
}
/* End of Pagination */

/* Clinic management */
.table-filter svg > path {
  stroke: var(--text-color);
}

/* CMS Styles */
.cms-card {
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px;
  border: 1px solid var(--white-border);
}
.cms-content {
  height: 600px;
  overflow-y: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-right: 10px;
}

.cms-form label {
  color: var(--titleColor);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.cms-form input,
.cms-form textarea {
  border-radius: 6px;
  height: 60px;
  border: 1px solid #adadad;
  padding: 16px 48px 16px 32px;
  width: 100%;
  outline: none;
}

.cms-form textarea {
  height: 120px;
}

.cms-form input:focus,
.cms-form textarea:focus {
  border: 2px solid #919099 !important;
}
.cms-form .form-item {
  margin-bottom: 20px;
}

/* Order management */
.order-message {
  padding: 20px;
}
.order-message svg {
  transform: scale(3);
}

.message-badge {
  position: absolute;
  top: -12px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 10px;
}

.overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  overflow: auto;
}

.chat-conversation-drawer {
  position: fixed;
  bottom: 40px; /* Adjust distance from the bottom as needed */
  right: 40px; /* Adjust distance from the right as needed */
  max-width: 500px; /* Adjust maximum width as needed */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0s linear 0.3s;
  z-index: 1000; /* Ensure the drawer appears above the overlay */
}

.chat-conversation-drawer.open {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.chat-body {
  padding: 10px;
  max-height: 600px; /* Adjust maximum height as needed */
  overflow-y: auto;
}

.chat-header h3 {
  margin: 0;
  font-size: 18px;
}

.chat-header button {
  background: none;
  border: none;
  cursor: pointer;
}

/* order details */
.btn-1 {
  border: 1px solid var(--primary-color);
  font-weight: 600;
  font-size: smaller;
  padding-left: 30px;
  padding-right: 30px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.btn-2 {
  background-color: var(--primary-color);
  color: white;
  font-size: smaller;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 10px;
}

.btn-3 {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  font-weight: 600;
  font-size: smaller;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 20px;
}
.btn-1:hover,
.btn-2:hover,
.btn-3:hover {
  background-color: var(--sub-menu-color);
  color: white;
}

.css-mq9gqg-ProgressBar {
  background-color: var(--primary-color) !important;
  margin-top: 4px !important;
  margin-left: -90px;
}
.css-qx19ch-ProgressMarker {
  background-color: var(--primary-color) !important;
  height: 15px !important;
  width: 15px !important;
}

/* Media Queries */
@media (min-width: 992px) {
  .w-lg-25 {
    width: 25%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-25 {
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .cms-card {
    width: 340px;
  }
}
/* Message */

.message-svg svg {
  fill: var(--primary-color);
}

.width {
  width: 400px;
}

.bg-color {
  background: #30c6b5;
}

.w-33 {
  width: 33.33%;
}

.w-66 {
  width: 66.66%;
}

.search-icon {
  stroke: #fafafa !important;
}
.outline-none {
  outline: none !important;
}

.outline-none:focus {
  outline: none !important;
  border: var(--text-color);
}
.nav-link {
  color: black !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: black !important;
  background-color: #f5f5f5 !important;
}

/* ---------------Add styles dashboard ---[Fuad] */

.table-container {
  max-height: 180px;
  overflow-y: auto;
}

.pdf-div {
  padding: 50px;
  background-color: #dedede;
  margin-top: 50px;
}
canvas.react-pdf__Page__canvas {
  margin-bottom: 50px;
  min-height: 400px !important;
  width: auto !important;
}

/* Custom Scrollbar */

.custom-scroll::-webkit-scrollbar {
  width: 4px !important;
}

.custom-scroll::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #c3c3c3 !important;
  border-radius: 3px !important;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #8a8a8a !important;
}


/* Transaction details */

.transaction-details-container {
  padding: 20px;
}

.transaction-details-row {
  display: flex;
  justify-content: space-between;
}

.transaction-details-column {
  flex: 1;
  padding: 10px;
}

.section-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.detail-label {
  font-weight: bold;
}

.error-message {
  color: red;
  font-weight: bold;
}

/* dropdown */

/* .input-wrapper-dropdown {
  position: relative;
}

.dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
} */

/* Datalist styles */

.country-code-list{
  max-height: 200px !important;
  overflow-y: auto !important;
}




